import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "../store/User/userApi";
import { logout, setCredentials } from "../store/auth";
import { getToken, storage } from "../utils/helper";
import { useGetAllCompaniesQuery } from "../store/DashBoard/DashBoardApi";

export const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const navigate = useNavigate();
  const token = storage["localStorage"].get("user_details_profile")?.token;
  const dispatch = useDispatch();
  console.log(localStorage.getItem(""));
  const {
    data: userProfile,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    isUninitialized,
    status,
  } = useGetAllCompaniesQuery();
  // const user =userProfile?.data
  console.log(
    isError,
    isLoading,
    token,
    isFetching,
    isUninitialized,
    status,
    "isErrorisErrorisError"
  );

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    console.log("Is working!!");
  }, [isError, navigate, token]);

  return children ? children : <Outlet />;
};
