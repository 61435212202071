import React, { useEffect, useMemo, useState } from "react";
import { ImageComponent } from "../../../../Components/Common/FallbackImage";
import ButtonComp from "../../../../Components/Ui/Button";
import { useNavigate } from "react-router-dom";
import InputComp from "../../../../Components/Ui/InputComp";
import ModalComp from "../../../../Components/Ui/ModalComp";
import { Country, State } from "country-state-city";

import SelectCompI from "../../../../Components/Ui/SelectCompI";
import { Controller } from "react-hook-form";
import { REGEX_PATTERNS } from "../../../../constants/errors";
import { useUploadFilesMutation } from "../../../../store/DashBoard/DashBoardApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function AddCompanyComp({
  control,
  getValues,
  errors,
  watch,
  setValue,
  addCompanyIsSuccess,
  modalShow,
  setModalShow,
  showImage,
  setShowImage,
}) {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [uploadFile, { isLoading: fileLoading }] = useUploadFilesMutation();

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const maxSize = 500 * 1024; // 500KB in bytes
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const formData = new FormData();
        formData.append("file", file);
        const responses = await uploadFile(formData);
        setShowImage(responses?.data?.data?.file_link);
        setValue("image", responses?.data?.data?.file_link);
        // Here, you can perform any actions with the selected file
        console.log("Selected file:", responses?.data?.data);
      } else {
        toast.error("File type or size not allowed.");
        console.log("File type or size not allowed.");
      }
    }
  };

  useEffect(() => {
    if (getValues("country")?.countryCode) {
      setValue("state", null);
    }
  }, [watch("country")?.countryCode]);

  const getAllCountries = useMemo(() => {
    const originalArray = Country?.getAllCountries();
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray.map((item) => ({
      // ...item,
      label: item?.name,
      value: item?.name,
      countryCode: item?.isoCode,
    }));
  }, []);

  const filteredStates = useMemo(() => {
    const originalArray = State?.getAllStates();
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray
      .filter(
        (item) => item.countryCode === watch("country")?.countryCode || ""
      )
      .map((item) => ({
        label: item.name,
        value: item.name,
      }));
  }, [watch("country")?.countryCode]);

  const formList = [
    {
      name: "name",
      placeholder: "Enter Company Name",
      label: "Company Name",
      type: "text",
    },
    {
      name: "contactPerson",
      placeholder: "Enter Contact Person's Name",
      label: "Contact Person's Name",
      type: "text",
    },
    {
      name: "email",
      placeholder: "Enter Email Address",
      label: "Email Address",
      type: "text",
      pattern: REGEX_PATTERNS?.EMAIL,
    },
    {
      name: "contactPhoneNo",
      placeholder: "+234",
      label: "Phone Number",
      type: "text",
      pattern: REGEX_PATTERNS?.NUMBER,
    },
    {
      name: "country",
      placeholder: "Select Country",
      label: "Country",
      type: "",
      option: getAllCountries,
    },
    {
      name: "state",
      placeholder: "Select State",
      label: "State",
      type: "",
      option: filteredStates,
    },
    {
      name: "address",
      placeholder: "Enter Full Address",
      label: "Full Address",
      type: "text",
    },
  ];

  const { t } = useTranslation();

  // console.log(filteredStates, Country?.getAllCountries(), watch("country"));
  return (
    <div className="pb-[69px]">
      <figure className="profile_hero_image relative mb-[33px]  h-min-[100px]:">
        <img src="/Manager/bg.svg" className="w-full min-h-fit " />
        <img
          src="/Manager/emptyPicState.svg"
          className="absolute  bottom-[-80%] lg:bottom-[-50%] lg:left-[57px] w-[100px] lg:w-[152px]"
        />
      </figure>
      <section className="view_profile_button flex justify-end mb-[61px]">
        <ButtonComp
          btnClassName={
            "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[17px] py-[14px]"
          }
          text={
            <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[18x] px-[30px]">
              {t("view-profile")}
            </div>
          }
        />
      </section>
      <section className="company_profile md:w-[93.5%] w-full mx-auto mb-[30px]">
        <div className="text-[22px] font-semibold">{t("company-profile")}</div>
        <div className="text-[#635F65] text-[14px]">
          {t("update-company-details")}
        </div>
      </section>

      {/* first_form_section */}
      <section className="form_container mb-[60px] flex flex-col items-center justify-center md:w-full w-full mx-auto">
        {formList?.splice(undefined, 4)?.map((item, i) => (
          <div
            className=" md:w-[94%] w-full flex md:flex-row  gap-[1rem] flex-col md:items-center items-start justify-between pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
            key={i}
          >
            <label className="form_title md:w-[24%] w-full block text-[1rem] font-[500] text-[#635F65]">
              {item?.name === "name"
                ? t("label-name")
                : item?.name === "contactPerson"
                ? t("label-contactperson")
                : item?.name === "email"
                ? t("label-email")
                : item?.name === "country"
                ? t("label-country")
                : item?.name === "state"
                ? t("label-state")
                : item?.name === "address"
                ? t("label-address")
                : item?.name === "contactPhoneNo"
                ? t("label-number")
                : ""}
            </label>
            <div className="md:w-[70%] w-full">
              <Controller
                // className="bg-red-800"
                name={item?.name}
                rules={{
                  required: `${item?.label} is required`,
                  pattern: item?.pattern,
                }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputComp
                    className="placeholder:text-gray-[#B7B7B7]"
                    onChange={onChange}
                    value={value}
                    error={errors[item?.name]?.message}
                    placeholder={
                      item?.name === "name"
                        ? t("placeholder-name")
                        : item?.name === "contactPerson"
                        ? t("placeholder-contactperson")
                        : item?.name === "email"
                        ? t("placeholder-email")
                        : item?.name === "country"
                        ? t("placeholder-country")
                        : item?.name === "state"
                        ? t("placeholder-state")
                        : item?.name === "address"
                        ? t("placeholder-address")
                        : item?.name === "contactPhoneNo"
                        ? t("placeholder-number")
                        : ""
                    }
                  />
                )}
              />
            </div>
          </div>
        ))}

        {/* User profile */}
        <section className="pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]  md:w-[94%] w-full flex items-center justify-between">
          <div className="flex_container flex items-center justify-center md:gap-[5rem] gap-[2rem]">
            <span className="profile_image block text-[16px] text-[#635F65] font-[500]">
              {t("profile-image")}
              <small className="block mt-[.5rem] text-[#635F65] max-[760px]:!text-[.69rem] text-[.8rem]">
                {t("max")}
                <br />
                {t("file-type")}
              </small>
            </span>
            <Controller
              name={"image"}
              rules={
                {
                  // required: `Image is required`,
                  // pattern:item?.pattern
                }
              }
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: "none" }}
                  accept=".jpeg, .jpg, .png"
                  onChange={(e) => handleFileChange(e)}
                />
              )}
            />
            <img
              src={showImage ? showImage : "/Manager/user.png"}
              className="!w-[60px] !h-[60px] object-cover rounded-full"
            />
          </div>

          {/* button_column */}
          <div className="flex  grow justify-center gap-[26px]">
            <ButtonComp
              text={
                <div className="text-[#635F65]" onClick={handleButtonClick}>
                  {fileLoading ? <Spinner size="sm" /> : t("upload")}
                </div>
              }
            />
            <ButtonComp
              text={<div className="text-[#D63434]">{t("delete")}</div>}
            />
          </div>
        </section>
      </section>

      {/*  Address and Territory*/}
      <section className="second_form_section flex flex-col items-start md:w-[95%] w-full mx-auto">
        <section className="section_title mb-[36px] w-full">
          <div className="text-[22px] font-semibold">{t("territory")}</div>
          <div className="text-[#635F65] text-[14px]">
            {t("Update-your-company-address")}
          </div>
        </section>

        {/* last form */}
        <section className="form_section w-full">
          {formList?.splice(undefined, 2)?.map((item, i) => (
            <div className="dropdown_field flex justify-center md:flex-row flex-col md:gap-[5rem] gap-[1rem] md:items-center items-start pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]">
              <label className="text-[16px] text-[#b9b9b9] md:w-[25%]">
                {item?.name === "country"
                  ? t("label-country")
                  : item?.name === "state"
                  ? t("label-state")
                  : ""}
              </label>
              <div className="md:w-[70%] w-full">
                <Controller
                  name={item?.name}
                  rules={{
                    required: `${item?.label} is required`,
                    pattern: item?.pattern,
                  }}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <SelectCompI
                      options={item?.option}
                      onChange={onChange}
                      value={value}
                      error={errors[item?.name]?.message}
                      placeholder={t("select")}
                    />
                  )}
                />
              </div>
            </div>
          ))}
          <div className="input_section mb-[60px] w-full">
            {formList?.splice(undefined, 1)?.map((item, i) => (
              <div
                className="flex md:flex-row flex-col md:items-center gap-[1rem] items-start justify-between pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px] w-full"
                key={i}
              >
                <div className="md:w-[25%] w-full text-[16px] text-[#b9b9b9]">
                  {t("placeholder-address")}
                </div>
                <div className="md:w-[70%] w-full">
                  <Controller
                    name={item?.name}
                    rules={{
                      required: `${item?.label} is required`,
                      pattern: item?.pattern,
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <InputComp
                        onChange={onChange}
                        value={value}
                        error={errors[item?.name]?.message}
                        placeholder={t("placeholder-address")}
                      />
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>

      <ModalComp
        show={modalShow}
        handleClose={() => setModalShow(false)}
        size={"lg"}
        bodyComponents={
          <AddProductModal
            navigate={navigate}
            onClose={() => {
              setModalShow(false);
            }}
          />
        }
      />
    </div>
  );
}

const AddProductModal = ({ navigate, onClose }) => {
  const { t } = useTranslation();

  return (
    <section className="">
      <div className="flex justify-center mb-[40px] ">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <section className="text-center flex flex-col items-center justify-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
          {t("company-successfully-created")}
        </h5>
        <div className="text-[16px] text-[#555555] md:w-[70%] w-full text-center">
          {t("company-added")}
        </div>
      </section>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          // To be corrected
          // Route properly /manager/:id
          onClick={() => navigate("/companies/:id")}
          text={t("view-profile")}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#4F008A] text-[#4F008A]"
          }
        />
        <ButtonComp
          onClick={() => navigate("/manager/add")}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={t("create-managers")}
        />
      </div>
    </section>
  );
};
