import { useTranslation } from "react-i18next";
import { ImageComponent } from "../Common/FallbackImage";
import { useEffect } from "react";

export const SideBarData = () => {
  const { t } = useTranslation();

  return [
    {
      name: "Overview",
      icon: <ImageComponent width={26} src="/Dashboard/1a.svg" />,
      icon1: (
        <ImageComponent
          width={26}
          height={26}
          src="/Dashboard/dashboard1.png"
        />
      ),
      path: "/",
      activeI: 1,
    },
    {
      name: "Companies",
      icon: <ImageComponent width={26} src="/icon/people.svg" />,
      icon1: <ImageComponent width={26} height={26} src="/icon/peoplei.svg" />,
      path: "/companies",
      activeI: 4,
    },

    // {
    //     name:'Med Reps',
    //     icon:<ImageComponent width={26} src='/Dashboard/profile-2user.svg' />,
    //     icon1:<ImageComponent width={26} height={26} src='/Dashboard/profile-2user1.png' />,
    //     path:'/med_rep',
    //     activeI:5,
    // //     // name:'Leads',path:'/lead'

    // },
  ];
};
