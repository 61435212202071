import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import ViewCompanyDetails from './Modules/Company'

export default function Companies() {
  return (
    <AdminLayout header='main' active={4}>
      <ViewCompanyDetails/>
    </AdminLayout>
  )
}
