import React from "react";
import { ImageComponent } from "../../../Common/FallbackImage";
import { BsCalendar2Event } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function SideDesc({ data }) {
  const { t } = useTranslation();
  return (
    <div className="xl:block hidden">
      <div className="  border-l-[1px] h-min-[90vh] border-[px] bg-[#FCFAFF]">
        <div className="bg-white mx-2 my-2  px-[14px] py-[16px]">
          <div className="mb-2 font-[500]">#{data?._id.substring(0, 5)}</div>
          <div className="flex-col items-center justify-center text-center">
            <div className="flex justify-center">
              <img
                src="/Images/companyIcon.png"
                className="w-[61px] h-[61px] rounded-full object-cover mb-[20px]"
              />
            </div>
            <div className="text-[19px] text-[#555555]">{data?.name}</div>
            <div className="text-[#8B8B8B] text-[14px]">
              {data?.email || "-"}
            </div>
            <div className="text-[#8B8B8B] text-[14px]">{data?.address}</div>
            <div className="text-[#8B8B8B] text-[13px] flex items-center justify-center mb-[34px]">
              <span>{t("last-visited")}:</span>
              <BsCalendar2Event size={10} className="mx-1" />
              April 5, 2023 at 10:25AM*
            </div>

            <div className="flex justify-center  gap-[38px] mb-[54px]">
              <a href={`tel:${data?.contactPhoneNo}`}>
                <ImageComponent src="/svg/phone.svg" />
              </a>
              <a href={`mailto:${data?.email}`}>
                <ImageComponent src="/svg/message.svg" />
              </a>
              <ImageComponent src="/svg/calendar.svg" />
            </div>
          </div>
        </div>
        <div className="bg-white mx-2 my-2  pl-[15%] py-[16px]">
          <div className="mb-[20px]">
            <div className="text-[14px]">{t("label-state")}:</div>
            <div className="text-[16px]">{data?.state || "-"}</div>
          </div>
          <div className="mb-[20px]">
            <div className="text-[14px]">{t("label-country")}:</div>
            <div className="text-[16px]">{data?.country || "-"}</div>
          </div>
          <div className="mb-[20px]">
            <div className="text-[14px]">{t("label-contactperson")}:</div>
            <div className="text-[16px]">{data?.contactPerson || "-"}</div>
          </div>

          <div className="mb-[20px]">
            <div className="text-[14px]">{t("label-address")}:</div>
            <div className="text-[16px]">{data?.address || "-"}</div>
          </div>
        </div>
        <div className="bg-white mx-2 my-2  px-[14px] py-[16px] h-[20vh]"></div>
      </div>
    </div>
  );
}
