import React from "react";
import InputComp from "../../../Ui/InputComp";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ManagerPage({ formList, control, errors }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="row  justify-between">
        {/* Left Side */}
        <div className="col-5">
          <div className="row">
            {formList?.slice(undefined, 4)?.map((item, i) => (
              <div className={`${item?.style}`}>
                <Controller
                  name={item?.name}
                  rules={{
                    required: `${item?.label} is required`,
                    pattern: item?.pattern,
                  }}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputComp
                      containerClassName={"mb-[20px]"}
                      label={
                        item?.name === "firstName"
                          ? t("first-name")
                          : item?.name === "lastName"
                          ? t("last-name")
                          : item?.name === "country"
                          ? t("label-country")
                          : item?.name === "state"
                          ? t("label-state")
                          : item?.name === "contactPhoneNo"
                          ? t("label-number")
                          : ""
                      }
                      labelClassName={
                        "!font-normal text-[#555555] text-[16px] mb-[15px]"
                      }
                      placeholder={item?.placeholder}
                      value={value}
                      onChange={onChange}
                      error={errors[item?.name]?.message}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right  side */}
        <div className="col-5">
          <div className="row">
            {formList?.slice(4, undefined)?.map((item, i) => (
              <div className={`${item?.style}`}>
                <Controller
                  name={item?.name}
                  rules={{
                    required: `${item?.label} is required`,
                    pattern: item?.pattern,
                  }}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputComp
                      containerClassName={"mb-[20px]"}
                      label={
                        item?.name === "contactPhoneNo"
                          ? t("label-contactperson")
                          : item?.name === "email"
                          ? t("label-email")
                          : item?.name === "address"
                          ? t("label-address")
                          : ""
                      }
                      labelClassName={
                        "!font-normal text-[#555555] text-[16px] mb-[15px]"
                      }
                      placeholder={item?.placeholder}
                      value={value}
                      onChange={onChange}
                      error={errors[item?.name]?.message}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
