import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useNavigate, useParams } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import {
  useGetAllCompaniesQuery,
  useGetAllCompanyManagersQuery,
  useGetAllCompanyMedRepsQuery,
  useGetAllManagersQuery,
  useGetCompanyQuery,
  useGetEachCompanyManagerQuery,
  useGetEachCompanyMedRepQuery,
} from "../../../store/DashBoard/DashBoardApi";
import ManagerPage from "./submodules/ManagerPage";
import Header from "./submodules/Header";
import ShowProfileTop from "./submodules/showProfileTop";
import TopHeader from "../../Common/TopHeader";
import CompanyManagersComp from "./submodules/CompanyManagers";
import { BsCalendar2Event } from "react-icons/bs";
import SideDesc from "./submodules/SideDesc";
import MedRepsComp from "./submodules/MedReps";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function ViewCompanyComp({
  listPage,
  setListPage,
  control,
  handleOnSubmit,
  setValue,
  updateCompanyIsSuccess,
  Companies,
  fileInputRef,
  handleButtonClick,
  showImage,
  fileLoading,
  handleFileChange,
  allMedReps,
}) {
  const [company, setCompany] = useState();
  const navigate = useNavigate();

  const pages = ["Overview", "Managers", "Med Reps"];
  const { id } = useParams();
  const queryOptions = {
    query: { id }, // Pass the parameters along with the query
    enabled: false, // Enable the query only if shouldFetchData is true
    skip: true,
  };

  const { data: CompanyManagers } = useGetAllManagersQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const { data: newCompany } = useGetCompanyQuery(id);

  console.log(newCompany?.data?.company, "REAL_COMPANY");

  const medReps = useGetAllCompanyMedRepsQuery();
  const { data } = useGetEachCompanyManagerQuery(id);

  const { data: medreps } = useGetEachCompanyMedRepQuery(id);

  useEffect(() => {
    setValue("name", newCompany?.data?.company?.name);
    setValue("email", newCompany?.data?.company?.email);
    setValue("address", newCompany?.data?.company?.address);
    setValue("contactPhoneNo", newCompany?.data?.company?.contactPhoneNo);
    setValue("contactPerson", newCompany?.data?.company?.contactPerson);
    setValue("state", newCompany?.data?.company?.state);
    setValue("country", newCompany?.data?.company?.country);
  }, [newCompany?.data?.company?._id]);

  useEffect(() => {
    if (id) {
      setCompany(
        Companies?.data?.overview?.table?.data.find(
          (item) => item?.companyData?._id === id
        )
      );
    }
  }, [id, Companies?.data?.overview?.table?.data]);

  // console.log(Companies?.data?.overview?.table?.companies, "new");
  // console.log(CompanyManagers?.data?.overview);
  const { t } = useTranslation();
  // console.log(company, "Company");
  const formList = [
    {
      name: "name",
      label: "Company Name",
      placeholder: company?.companyData?.name,
      value: company?.name,
    },
    {
      name: "contactPerson",
      label: "Contact Person’s Name",
      placeholder: company?.companyData?.contactPerson,
      value: company?.contactPerson,
    },
    {
      name: "country",
      placeholder: company?.companyData?.country,
      label: "Country",
      style: "col-lg-6",
      value: company?.country,
    },
    {
      name: "state",
      placeholder: company?.companyData?.state,
      label: "State",
      style: "col-lg-6",
      value: company?.state,
    },
    {
      name: "email",
      placeholder: company?.companyData?.email,
      label: "Email address",
      value: company?.email,
    },
    {
      name: "contactPhoneNo",
      placeholder: company?.companyData?.contactPhoneNo,
      label: "Phone Number",
      iconRight: "+234",
      value: company?.contactPhoneNo,
    },
    {
      name: "address",
      label: "Company Full Address",
      placeholder: company?.companyData?.address,
      value: company?.address,
    },
  ];

  const pageSet = [
    {
      name: "Overview",
      component: <ManagerPage control={control} formList={formList} />,
    },
    {
      name: "Managers",
      component: (
        <CompanyManagersComp
          Data={data?.data?.overview?.table?.managers}
          managers={data?.data?.overview?.manager}
        />
      ),
    },
    {
      name: "Med Reps",
      component: (
        <MedRepsComp
          Data={medreps?.data?.overview?.table?.MedReps}
          secondData={medreps?.data?.overview?.medRep}
        />
      ),
    },
  ];

  if (listPage === "Medical Reps") {
    console.log("WE GOT IT!!!");
  }
  return (
    <div className={`flex gap-2 h-min-[90vh] `}>
      <div
        className={`pb-[69px] w-full  ${
          listPage !== "Overview" && " xl:w-[75%]"
        }`}
      >
        <TopHeader
          // title={`Companies (${CustomerData?.data?.length || 0})`}
          title={t("companies")}
          btnText={t("download-report")}
          btnTextII={t("add-manager")}
          addBtnAction={() => navigate("/manager/add")}
          navigate={navigate}
        />
        {listPage === "Overview" && (
          <ShowProfileTop
            company={company}
            handleOnSubmit={handleOnSubmit}
            updateCompanyIsSuccess={updateCompanyIsSuccess}
            fileInputRef={fileInputRef}
            handleButtonClick={handleButtonClick}
            uploadImage={showImage}
            fileLoading={fileLoading}
            handleFileChange={handleFileChange}
          />
        )}

        <Header
          listPage={listPage}
          setListPage={setListPage}
          pages={pages}
          showFull={listPage === "Overview" ? true : false}
        />

        {pageSet?.find((item) => item?.name === listPage)?.component}
      </div>
      <div className={listPage !== "Overview" && `xl:w-[25%]`}>
        {/* {console.log(company, "COMPANY DATA")} */}
        {listPage !== "Overview" && <SideDesc data={company?.companyData} />}
      </div>
    </div>
  );
}

const AddProductModal = ({ navigate }) => {
  return (
    <>
      <div className="flex justify-center mb-[40px]">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
          {t("company-successfully-created")}
        </h5>
        <div className="text-[16px]">{t("company-added")}</div>
      </div>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          onClick={() => navigate("/")}
          text={"go-to-dashboard"}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#3DA84A] text-[#3DA84A]"
          }
        />
        <ButtonComp
          onClick={() => navigate("/companies/add")}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={t("create-managers")}
        />
      </div>
    </>
  );
};
