import React from "react";
import ButtonComp from "../../../Ui/Button";
import { useTranslation } from "react-i18next";

export default function ShowProfileTop({
  company,
  handleOnSubmit,
  updateCompanyIsSuccess,
  handleButtonClick,
  fileInputRef,
  fileLoading,
  handleFileChange,
  uploadImage,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="relative mb-[33px] ">
        <img src="/Manager/bg.svg" className="w-full" />
        <div className="absolute  bottom-[-80%] lg:bottom-[-50%] lg:left-[57px] w-[100px] lg:w-[152px] flex items-center gap-[12px]">
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            accept=".jpeg, .jpg, .png"
            onChange={(e) => handleFileChange(e)}
          />
          {/* <div className={fileLoading&&'opacity-0'}> */}
          <img
            src={
              uploadImage ||
              company?.companyData?.image ||
              "/Manager/emptyPicState.svg"
            }
            className="cursor-pointer w-[60px] h-[60px] lg:w-[112px] lg:h-[112px] rounded-full object-contain bg-white"
            onClick={handleButtonClick}
          />
          {/* </div> */}
          <div className="whitespace-nowrap text-[24px] font-bold">
            {company?.companyData?.name}
          </div>
        </div>
      </div>
      <div className="flex justify-end mb-[61px]">
        <ButtonComp
          onClick={handleOnSubmit}
          loader={updateCompanyIsSuccess}
          disabled={updateCompanyIsSuccess}
          btnClassName={
            "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[17px] py-[14px] "
          }
          text={
            <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[18x] px-[30px]">
              {t("edit-profile")}
            </div>
          }
        />
      </div>
    </div>
  );
}
