import { BsFillBuildingsFill, BsThreeDotsVertical } from "react-icons/bs";
import DropDownComp from "../../../../Components/Ui/DropDown";
import moment from "moment";
import Beta from "../../../../Components/Common/beta";
import { ImageComponent } from "../../../../Components/Common/FallbackImage";
import { useTranslation } from "react-i18next";
// import { ImageComponent } from "../../Common/FallbackImage";

export const statusStyleII = (status) => {
  switch (status) {
    case "In Progress":
      return "border-[#044E54] border-[1px] rounded-md px-[15px] py-1 bg-[#E5DAEE]";

    case "New":
      return "border-[#1D60D1] border-[1px] rounded-md px-[15px] py-1 bg-[#EEE8F4]";

    case "Open":
      return "border-[#E9E911] border-[1px] rounded-md px-[15px] py-1 bg-[#F5F1E3]";

    case "n":
      return "border-[#E9E911] border-[1px] rounded-md px-[15px] py-1 bg-[#F5F1E3]";

    case "PENDING":
      return "border-[#044E54] border-[1px] rounded-md px-[15px] py-1 bg-[#E5DAEE] capitalize";
    case "COMPLETED":
      return "border-[#044E54] border-[1px] rounded-md px-[15px] py-1 bg-[#D6DCE7]";
    default:
      break;
  }
};

export const statusStyle = (status) => {
  switch (status) {
    case "In Progress":
      return "border-[#044E54] border-[1px] rounded-full px-[15px] py-1 bg-[#E5DAEE]";

    case "New":
      return "border-[#1D60D1] border-[1px] rounded-full px-[15px] py-1 bg-[#EEE8F4]";

    case "Open":
      return "border-[#E9E911] border-[1px] rounded-full px-[15px] py-1 bg-[#F5F1E3]";

    case "n":
      return "border-[#E9E911] border-[1px] rounded-full px-[15px] py-1 bg-[#F5F1E3]";

    default:
      break;
  }
};

// const { t } = useTranslation();
export const CustomerColumns = (tableDropDown, Data = []) => {
  return [
    {
      name: "ID",
      selector: (row) => (
        <div className="w-full">#{row?.companyData?._id.substring(0, 5)}</div>
      ),
      width: "120px",
      header: ({ name }) => <span title="Customer Id">{name}</span>,
    },
    {
      name: "Company Name",
      selector: (row) => (
        <div className="flex items-center gap-2">
          {row?.logo ? (
            <ImageComponent
              width={42}
              height={42}
              className="object-cover"
              src={row?.logo}
            />
          ) : (
            <BsFillBuildingsFill size={20} />
          )}
          {row?.companyData?.name?.length > 27
            ? `${row?.companyData?.name}...`
            : row?.companyData?.name}
        </div>
      ),
      width: "250px",
      header: ({ name }) => <span title="Customer Name">{name}</span>,
    },

    {
      name: "Email Address",
      selector: (row) => (
        <span>
          {row?.companyData?.email?.length > 27
            ? `${row?.companyData?.email}...`
            : row?.companyData?.email}
        </span>
      ),
      header: ({ name }) => <span title="Contact No">{name}</span>,
      width: "250px",
    },
    {
      name: "Country",
      selector: (row) => (
        <div className={`w-[100px]  text-left }`}>
          {row?.companyData?.country}
        </div>
      ),
      header: ({ name }) => <span title="Country">{name}</span>,
    },
    {
      name: "Med Rep",
      selector: (row) => (
        <div className={`w-[100px]  text-left }`}>{row?.medRepCount}</div>
      ),
      header: ({ name }) => <span title="Med Rep">{name}</span>,
    },
    {
      name: "Status",
      selector: (row) =>
        row?.companyData?.active === true ? (
          <div className="text-[#3DA84A] h-[1.5rem] w-[4rem] rounded-full flex items-center justify-center bg-[#EFFDF9]">
            Active
          </div>
        ) : (
          <div className="text-[#FFC80A] h-[1.5rem] w-[4rem] rounded-full flex items-center justify-center bg-yellow-100">
            Inactive
          </div>
        ),
      header: ({ name }) => <span title="customer Tier">{name}</span>,
    },

    {
      name: "Date Added",
      selector: (row) =>
        moment(row?.companyData?.createdAt).format("MMM Do YY"),
      header: ({ name }) => <span title="">{name}</span>,
    },
    {
      name: "Action",
      header: ({ name }) => <span title="">{name}</span>,
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => (
        <DropDownComp
          row={row}
          options={tableDropDown}
          dropDownText={<BsThreeDotsVertical color="#7572EA" />}
        />
      ),
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];
