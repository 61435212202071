// In ViewCompanyDetails.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TableComp } from "../../../../Components/Common/TableComp";
import TopHeader from "../../../../Components/Common/TopHeader";
import { CustomerColumns } from "./Data";
import {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useGetAllManagerQuery,
  useUpdateLeadsMutation,
} from "../../../../store/Customer/CustomerApi";

import {
  useGetAllCompaniesQuery,
  useGetOverviewQuery,
} from "../../../../store/DashBoard/DashBoardApi"; // Adjust the path as needed
import { FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ViewCompanyDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchField, setSearchField] = useState("");

  const {
    data: Companies,
    isLoading,
    isError,
    error,
  } = useGetAllCompaniesQuery({ page: currentPage, limit });

  const overview = useGetOverviewQuery();

  const [
    updateLeads,
    {
      isLoading: updateLoading,
      isError: updateError,
      data: updateData,
      error: updateErrorData,
      isSuccess: updateLeadIsSuccess,
    },
  ] = useUpdateLeadsMutation();

  useEffect(() => {
    if (isError) {
      Array.isArray(error?.data?.message)
        ? error?.data?.message?.map((item) => toast.error(item))
        : toast.error(error?.data?.message);
    }
  }, [error?.data?.message, isError]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1); // reset to first page when limit changes
  };

  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          <FiEdit size={18} color="#4F008A" /> View Full Info
        </div>
      ),
      action: (row) => {
        navigate(`/companies/${row?.companyData?._id}`);
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          <img src="/icon/refresh.svg" alt="" />
          Add New Manager
        </div>
      ),
      action: (row) => {
        navigate(`/manager/add`);
      },
    },
    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center px-3">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Company
        </div>
      ),
      action: (row) => {
        // Add delete logic here
      },
    },
  ];

  return (
    <div>
      <TopHeader
        title={t("companies")}
        btnTextII={t("add-company")}
        btnText={t("add-company-admin")}
        addBtnAction={() => navigate("/companies/add")}
        addBtnIIAction={() => navigate("/manager/add")}
      />

      <div className="row mb-5 flex-wrap">
        {[
          {
            name: "Total Companies",
            count: overview?.data?.data?.overview?.company?.totalCompanies,
            suffixIcon: (
              <small className="w-full flex items-end justify-end text-yellow">
                {t("view-all")}
              </small>
            ),
          },
          {
            name: "Active Companies",
            count:
              overview?.data?.data?.overview?.company?.totalActiveCompanies,
          },
          {
            name: "In-Active Companies",
            count:
              overview?.data?.data?.overview?.company?.totalInActiveCompanies,
          },
        ]?.map((item, i) => (
          <div className="items_card w-full col-lg-4 flex-grow h-full" key={i}>
            <div className="px-lg-2 ">
              <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                <div className="d-flex justify-content-between align-items-start mb-3">
                  <div className=" text-[18px]">
                    {item?.name === "Total Companies"
                      ? t("total-companies")
                      : item?.name === "Active Companies"
                      ? t("active-companies")
                      : t("inactive-companies")}
                  </div>
                  <div className="icon_section">
                    <div className=" text-[18px]">
                      <img src="/icon/dots.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className=" text-[#060D1C] flex items-center justify-between group-hover:text-white font-5 text-[25px]">
                  {!overview?.data?.data?.overview?.company
                    ? "..."
                    : item?.count}
                  <Link
                    to="/"
                    className="block text-[.85rem] !text-[#4F008A] mt-[.5rem]"
                  >
                    <div>{item?.suffixIcon}</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="mb-3">
        <label>
          {t("items-per-page")}:{" "}
          <select value={limit} onChange={handleLimitChange}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </label>
      </div> */}

      <section className="table_Section">
        <TableComp
          Data={Companies?.data?.overview?.table?.data}
          columnsComp={CustomerColumns(
            tableDropDown,
            Array.isArray(Companies?.data?.overview?.table?.data) && [
              ...Companies?.data?.overview?.table?.data,
            ]
          )}
          pagination={{
            currentPage,
            totalPages: Math.ceil((Companies?.data?.total || 0) / limit),
            onPageChange: handlePageChange,
          }}
        />
      </section>
    </div>
  );
}
