import React, { useState } from "react";
import styled from "styled-components";
import Header from "../Components/Common/Header";
import SideBar from "../Components/Common/SideBar";
import withAuth from "../Hoc/withAuth";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../store/auth";
import HeaderDesc from "../Components/Common/HeaderDesc";

function AdminLayout({
  children,
  active,
  noPadding,
  header = "main",
  topText,
  onClick,
  isLoading,
}) {
  const [toggle, setToogle] = useState(false);
  const userDetails = useSelector(selectCurrentUserData);
  // const Check =useSelector(Check);
  // console.log(userDetails ,'userDetails')

  // console.log(user,'userProfile')

  // console.log(active, "active");
  const [toggleSide, setToggleSide] = useState(false);
  const cancel = () => {
    setToggleSide(false);
    console.log("Cancel");
  };
  return (
    <AdminLayoutStyled
      toggleSide={toggleSide}
      className="d-flex px-lg-0 min-vh-100"
    >
      {/* <div
        className="w-full h-full hidden max-[800px]:block fixed top-0 bg-black/50 z-[5]"
        onClick={cancel}
      > */}
      <aside className="border border-1 h-100 side z-[7]">
        <SideBar
          active={active}
          data={userDetails}
          setToggleSide={setToggleSide}
        />
      </aside>
      {/* </div> */}
      <main className="main w-full">
        {header === false ? null : header === "main" ? (
          <Header
            setToogle={setToogle}
            setToggleSide={setToggleSide}
            data={userDetails}
          />
        ) : (
          <HeaderDesc
            isLoading={isLoading}
            onClick={onClick}
            topText={topText}
          />
        )}
        <section
          className={`${
            !noPadding && "p-2 p-lg-4"
          } animate__fadeIn animate__animated w-full`}
        >
          {children}
        </section>
      </main>
    </AdminLayoutStyled>
  );
}

const AdminLayoutStyled = styled.div`
position: relative;
.side {
  position: fixed;
  width: 260px;
  // overflow-y: auto;
  top: 0;
  bottom: 0;
  scrollbar-width: thin;
  z-index:90
  scrollbar-width: none;
}
.main {
  margin-left: 260px;
  overflow:hidden
}
.sideBar {
  transition: all 1s;
  scrollbar-width: none;
  height: 500px;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
}

@media only screen and (max-width: 800px) {
  .main {
    margin-left: 0px;
   
    
  }
  .side {
    background:white;
    visibility: ${(props) =>
      props?.toggleSide ? "visible" : "hidden"}!important;
    transition: all 0.5s;
    width: ${(props) => (props?.toggleSide ? "260px" : "0px")}!important;
    z-index: 200;
    display:${(props) =>
      props?.toggleSide === true ? "block" : "none"}!important;
  }
}
`;

export default AdminLayout;
