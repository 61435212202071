import React from "react";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { ImageComponent } from "../Common/FallbackImage";
import ButtonComp from "./Button";

export default function ModalComp({ show, handleClose, bodyComponents, size }) {
  return (
    <Modal show={show} onHide={handleClose} centered size={size || "md"}>
      <Modal.Body>
        {/* close icon */}
        <section className="close_icon flex justify-end">
          <button
            className="bg-[#FBEBEB] rounded-full p-1 w-[36px] h-[36px] items-center justify-center flex mb-[20px] cursor-pointer"
            onClick={handleClose}
          >
            <IoMdClose size={20} color="#D63434" />
          </button>
        </section>
        <div>{bodyComponents}</div>
      </Modal.Body>
    </Modal>
  );
}
