import React, { useEffect, useMemo, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useNavigate } from "react-router-dom";
import InputComp from "../../Ui/InputComp";

import SelectCompI from "../../Ui/SelectCompI";
import {
  useGetAllCompaniesQuery,
  useUploadFilesMutation,
} from "../../../store/DashBoard/DashBoardApi";
import { Country, State } from "country-state-city";
import { Controller } from "react-hook-form";
import { REGEX_PATTERNS } from "../../../constants/errors";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalComp from "../../Ui/ModalComp";
export default function AddCompanyComp({
  control,
  getValues,
  errors,
  watch,
  setValue,
  addCompanyIsSuccess,
  modalShow,
  setModalShow,
  showImage,
  setShowImage,
}) {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [uploadFile, { isLoading: fileLoading }] = useUploadFilesMutation();
  const {
    data: Companies,
    // isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCompaniesQuery();

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const maxSize = 500 * 1024; // 500KB in bytes
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const formData = new FormData();
        formData.append("file", file);
        const responses = await uploadFile(formData);
        setShowImage(responses?.data?.data?.file_link);
        setValue("image", responses?.data?.data?.file_link);
        // Here, you can perform any actions with the selected file
        console.log("Selected file:", responses?.data?.data);
      } else {
        toast.error("File type or size not allowed.");
        console.log("File type or size not allowed.");
      }
    }
  };

  useEffect(() => {
    if (getValues("country")?.countryCode) {
      setValue("state", null);
    }
  }, [watch("country")?.countryCode]);

  const getAllCountries = useMemo(() => {
    const originalArray = Country?.getAllCountries();
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray.map((item) => ({
      // ...item,
      label: item?.name,
      value: item?.name,
      countryCode: item?.isoCode,
    }));
  }, []);

  // console.log(Companies?.data?.overview?.table?.data, "Companies For Bayo");
  // const getAllAvailableCompaniesNot = useMemo(() => {
  //   const originalArray = Companies?.data?.overview?.table?.data;
  //   if (!originalArray) return [];

  //   console.log(originalArray[0].name, "original Array");

  //   // Modify each item in the array and return the modified array
  //   return originalArray.map((item) => ({
  //     ...item,

  //     label: item?.companyData?.name,
  //     value: item?.companyData?._id,
  //     id: item?.companyData?._id,
  //   }));
  // }, []);

  const getAllAvailableCompanies = () => {
    const originalArray = Companies?.data?.overview?.table?.data;
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray.map((item) => ({
      ...item,

      label: item?.companyData?.name,
      value: item?.companyData?.name,
      id: item?.companyData?._id,
    }));
  };

  const filteredStates = useMemo(() => {
    const originalArray = State?.getAllStates();
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray
      .filter(
        (item) => item.countryCode === watch("country")?.countryCode || ""
      )
      .map((item) => ({
        label: item.name,
        value: item.name,
      }));
  }, [watch("country")?.countryCode]);
  //  console.log(getAllCountries, "getCategories");
  //Companies
  const FormList = [
    {
      name: "firstName",
      label: "First Name",
      placeholder: "Enter First Name",
    },
    {
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter Last Name",
    },
    {
      name: "email",
      label: "Email address",
      placeholder: "Enter email address",
      pattern: REGEX_PATTERNS?.EMAIL,
    },
    {
      name: "",
      label: "Profile Image",
      placeholder: "",
      type: "Image",
    },
    {
      name: "contactPhoneNo",
      label: "Phone Number",
      placeholder: "",
      suffixIcon: (
        <div className="text-[#B7B7B7] border-r-[2px] border-r-[#282728] mr-[11px] pr-[11px]">
          +234
        </div>
      ),
      pattern: REGEX_PATTERNS?.NUMBER,
    },
    {
      name: "companyId",
      label: "Company",
      placeholder: "Select Company",
      type: "select",
      option: getAllAvailableCompanies(),
    },
    {
      name: "country",
      label: "Country",
      placeholder: "Select Country",
      option: getAllCountries,
      type: "select",
    },
    {
      name: "state",
      label: "State",
      placeholder: "Select State",
      option: filteredStates,
      type: "select",
    },
    {
      name: "address",
      label: "Full Address",
      placeholder: "Enter Full Address",
    },
  ];

  const { t } = useTranslation();

  return (
    <div className="pb-[69px]">
      <hgroup className="relative mb-[33px]">
        <img src="/Manager/bg.svg" className="w-full" />
        <img
          src="/Manager/emptyPicState.svg"
          className="absolute  bottom-[-80%] lg:bottom-[-50%] lg:left-[57px] w-[100px] lg:w-[152px]"
        />
      </hgroup>
      <div className="flex justify-end mb-[61px]">
        <ButtonComp
          btnClassName={
            "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[17px] py-[14px] "
          }
          text={
            <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[18x] px-[30px]">
              {t("view-profile")}
            </div>
          }
        />
      </div>
      <section className="mb-[36px] md:w-[96%] w-full mx-auto">
        <div className="text-[22px] font-semibold">{t("personal-profile")}</div>
        <div className="text-[#635F65] text-[14px]">
          {t("update-personal-details")}
        </div>
      </section>

      {/* form section */}
      <div className="mb-[60px] mx-auto md:w-[96.5%]">
        {FormList?.splice(undefined, 6)?.map((item, i) => {
          if (!item?.type) {
            return (
              // for inputs
              <div
                className="flex md:items-center items-start md:flex-row flex-col justify-start pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
                key={i}
              >
                <label className="text-[16px] text-[#635F65] md:w-[25%] w-full">
                  {item?.name === "firstName"
                    ? t("manager-first-name-label")
                    : item?.name === "lastName"
                    ? t("manager-last-name-label")
                    : item?.name === "email"
                    ? t("label-email")
                    : item?.name === "contactPhoneNo"
                    ? t("manager-phone-number")
                    : item?.name === "state"
                    ? t("placeholder-state")
                    : item?.name === "companyId"
                    ? t("company")
                    : ""}
                </label>
                <div className="md:w-[63%] w-full">
                  <Controller
                    name={item?.name}
                    rules={{
                      required: `${item?.label} is required`,
                      pattern: item?.pattern,
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <InputComp
                        onChange={onChange}
                        value={value}
                        error={errors[item?.name]?.message}
                        suffixIcon={item?.suffixIcon}
                        placeholder={
                          item?.name === "firstName"
                            ? t("manager-first-name-placeholder")
                            : item?.name === "lastName"
                            ? t("manager-last-name-placeholder")
                            : item?.name === "email"
                            ? t("placeholder-email")
                            : item?.name === "contactPhoneNo"
                            ? t("manager-phone-number")
                            : item?.name === "state"
                            ? t("placeholder-state")
                            : item?.name === "companyId"
                            ? t("company")
                            : ""
                        }
                      />
                    )}
                  />
                </div>
              </div>
            );
          }
          // select
          if (item?.type === "select") {
            return (
              <div
                className="flex md:items-center items-start md:flex-row flex-col justify-start pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
                key={i}
              >
                <label className="text-[16px] text-[#635F65] md:w-[25%] w-full">
                  {item?.name === "contactPhoneNo"
                    ? t("manager-phone-number")
                    : item?.name === "state"
                    ? t("label-state")
                    : item?.name === "companyId"
                    ? t("company")
                    : item?.name === "country"
                    ? t("label-country")
                    : ""}
                </label>
                <div className="md:w-[63%] w-full">
                  <Controller
                    name={item?.name}
                    rules={{
                      required: `${item?.label} is required`,
                      pattern: item?.pattern,
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectCompI
                        options={item?.option}
                        onChange={onChange}
                        value={value}
                        error={errors[item?.name]?.message}
                        placeholder={
                          item?.name === "companyId"
                            ? t("select-company")
                            : item?.name === "country"
                            ? t("label-country")
                            : ""
                        }
                      />
                    )}
                  />
                </div>
              </div>
            );
          }

          // profile image section
          if (item?.type === "Image") {
            return (
              <section
                className="flex items-center pb-[20px] md:gap-[3rem] gap-[1rem] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
                key={i}
              >
                <section className="image_container flex items-center justify-center gap-[3rem]">
                  <span className="block text-[16px] text-[#635F65] font-medium">
                    {t("profile-image")}
                    <small className="block mt-[.5rem] text-[#635F65] max-[760px]:!text-[.69rem] text-[.8rem]">
                      {t("max")}
                      <br />
                      {t("file-type")}
                    </small>
                  </span>
                  <Controller
                    name={"image"}
                    rules={{
                      required: `Image is required`,
                      // pattern:item?.pattern
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        accept=".jpeg, .jpg, .png"
                        onChange={(e) => handleFileChange(e)}
                      />
                    )}
                  />
                </section>
                <img
                  src={showImage ? showImage : "/Manager/user.png"}
                  className="!w-[60px] !h-[60px] object-cover rounded-full"
                />

                {/* upload_delete section */}
                <section className="flex grow items-start justify-center h-[10vh] gap-[26px] ">
                  <ButtonComp
                    text={
                      <div
                        className="text-[#635F65] font-semibold"
                        onClick={handleButtonClick}
                      >
                        {fileLoading ? <Spinner size="sm" /> : t("upload")}
                      </div>
                    }
                  />
                  <ButtonComp
                    text={
                      <div className="text-[#D63434] font-[600]">
                        {t("delete")}
                      </div>
                    }
                  />
                </section>
                <div className="text-[13px] text-red-600">
                  {errors["image"]?.message}
                </div>
              </section>
            );
          }
        })}
      </div>

      {/*last section  */}
      <section className="company_address">
        <div className="mb-[36px]">
          <div className="text-[22px] font-semibold">{t("territory")}</div>
          <div className="text-[#635F65] text-[14px]">
            {t("update-your-personal-address")}
          </div>
        </div>
        <div className="mx-auto w-full">
          {FormList?.map((item, i) => {
            if (item?.type === "select") {
              return (
                <div
                  className=" w-full flex md:items-center items-start md:flex-row flex-col pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
                  key={i}
                >
                  <label className="text-[16px] text-[#635F65] md:w-[25%] w-full">
                    {item?.name === "state"
                      ? t("label-state")
                      : item?.name === "country"
                      ? t("label-country")
                      : ""}
                  </label>
                  <div className=" md:w-[63%] w-full">
                    <div className="">
                      <Controller
                        name={item?.name}
                        rules={{
                          required: `${item?.label} is required`,
                          pattern: item?.pattern,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectCompI
                            options={item?.option}
                            onChange={onChange}
                            value={value}
                            error={errors[item?.name]?.message}
                            placeholder={
                              item?.name === "state"
                                ? t("label-state")
                                : item?.name === "country"
                                ? t("placeholder-country")
                                : ""
                            }
                            className="placeholder:text-gray-500"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            }
            if (!item?.type) {
              return (
                <div
                  className="flex md:items-center items-start md:flex-row flex-col justify-start pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
                  key={i}
                >
                  <label className="text-[16px] text-[#635F65] md:w-[25%] w-full">
                    {item?.name === "address" ? t("label-address") : ""}
                  </label>
                  <div className="md:w-[63%] w-full">
                    <Controller
                      name={item?.name}
                      rules={{
                        required: `${item?.label} is required`,
                        pattern: item?.pattern,
                      }}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <InputComp
                          onChange={onChange}
                          value={value}
                          error={errors[item?.name]?.message}
                          suffixIcon={item?.suffixIcon}
                          placeholder={
                            item?.name === "address"
                              ? t("placeholder-address")
                              : ""
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </section>

      <ModalComp
        show={modalShow}
        handleClose={() => setModalShow(false)}
        size={"lg"}
        bodyComponents={
          <AddProductModal
            navigate={navigate}
            onClose={() => {
              setModalShow(false);
            }}
          />
        }
      />
    </div>
  );
}

const AddProductModal = ({ navigate, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-center mb-[40px]">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <section className="text-center mb-[30px] lg:mb-[62px] flex items-center justify-center flex-col">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
          {t("managers-successfully-created")}
        </h5>
        <span className="block text-[16px] text-[#555555] md:w-[70%] w-full text-center">
          {t("manager-added")}
        </span>
      </section>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          onClick={() => navigate("/")}
          text={t("go-home")}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#3DA84A] text-[#3DA84A]"
          }
        />
        <ButtonComp
          // To be corrected
          // Route properly /manager/:id
          onClick={() => navigate("/manager/:id")}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={t("view-manager")}
        />
      </div>
    </>
  );
};
