import React, { useEffect, useState } from "react";
import { SideBarData } from "../Data/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { ImageComponent } from "./FallbackImage";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth";
import { useGetUserProfileQuery } from "../../store/User/userApi";
import { useTranslation } from "react-i18next";
import ButtonComp from "../Ui/Button";
import { HiDotsVertical } from "react-icons/hi";
import DropDownComp from "../Ui/DropDown";
import { useUserLoginMutation } from "../../store/auth/authApi";
export default function SideBar({ active, data, setToggleSide }) {
  const navigate = useNavigate();

  // const { data: userProfile } = useGetUserProfileQuery();
  const [dropDown, setDropDown] = useState(false);
  const [current, setCurrent] = useState({});

  const userInfo = useSelector((state) => state.userData);

  const user = useGetUserProfileQuery();

  // const [userLogin, { isLoading, isSuccess, data }] = useUserLoginMutation();

  const languages = [
    { code: "en", lang: "English" },

    { code: "fr", lang: "French" },
  ];

  // translation logic
  const { i18n, t } = useTranslation();
  const btnChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const dispatch = useDispatch();
  return (
    <aside className="overflow-y-scroll z-[10] h-[100vh] check overflow-x-hidden">
      <section
        className="position-relative text-center flex justify-center items-center mx-auto mb-5 border-bottom  px-[.3rem] w-[90%]"
        style={{ height: "92.4px" }}
      >
        {/* <Link
          to="/"
          className="text-center flex justify-center items-center h-full"
        > */}
        <ImageComponent className="object-contain w-[70%]" src={"/logo1.svg"} />
        {/* </Link> */}
      </section>
      <section className="px-3">
        <div className="sidebar_links">
          {SideBarData()?.map(
            ({ name, icon, icon1, path, activeI, subItem }, i) => (
              <div
                key={i}
                // to={path}
              >
                <div
                  className={`ps-4 d-flex align-items-center gap-3 fw-medium mb-2 py-3  px-3 cursor-pointer ${
                    active === activeI
                      ? "bg-[#f5eff8] text-[#333333] "
                      : "blackII"
                  }`}
                  onClick={() => navigate(path)}
                >
                  {icon1}
                  {name === "Overview" ? t("overview") : t("companies")}
                </div>
              </div>
            )
          )}
        </div>
        <div className="Admin_section pt-[155px]" style={{ height: "20vh" }}>
          <div className="border-b-[#E5E5EF] border-b-[1px] border-t-[1px] border-t-[#E5E5EF] h-[132px] mb-[64px]"></div>
          <div className="flex  items-center gap-4 mb-[32px] ">
            <section className="flex  items-center gap-2">
              <img src="/icon/user.png" className="w-[40px]" />
              <div className="admin_section">
                <div className="text-[14px] text-[#6A6A6A]">
                  {t("administrator")}
                </div>
                <div className="font-medium">
                  {data?.firstName} {data?.lastName}
                </div>
              </div>
            </section>
            <div>
              <DropDownComp
                dropDownText={<HiDotsVertical />}
                options={[
                  {
                    name: t("logout"),
                    action: () => {
                      navigate("/login");
                    },
                  },
                ]}
              />
            </div>
          </div>

          <div className="border-[1px] border-[#E5E5EF] px-[9px] py-[7px] flex justify-between gap-3">
            {languages?.map((lng) => (
              <ButtonComp
                text={lng?.lang}
                btnClassName={`w-full ${
                  lng?.code === i18n.language ? "bg-[#EDE6F3]" : ""
                } text-black col-12 text-[14px] py-[10px] font-medium`}
                wrapper={"flex-1"}
                onClick={() => btnChange(lng?.code)}
              />
            ))}

            {/* <ButtonComp
              text={"English"}
              btnClassName={`w-full ${
                btnStyle === true ? "bg-[#EDE6F3]" : ""
              } text-black col-12 text-[14px] py-[10px]  font-medium`}
              wrapper={"flex-1 "}
              onClick={() => btnChange("English")}
            />
            <ButtonComp
              text={"French"}
              btnClassName={`w-full ${
                btnStyle === false ? "bg-[#EDE6F3]" : ""
              } py-[10px] text-[#757575] col-12 text-[14px] font-medium`}
              wrapper={"flex-1 "}
              onClick={() => btnChange("French")}
            /> */}
          </div>

          <section className="cancel mx-auto hidden max-[800px]:block mt-[3rem]">
            <button
              onClick={() => setToggleSide(false)}
              className="flex mx-auto items-center justify-center  w-[3.4rem] h-[3.4rem] border-solid border-[2px] border-[#c0bebe] rounded-full"
            >
              <img src="/svg/cancel.svg" alt="" />
            </button>
          </section>

          <div className=" pt-[155px]" style={{ height: "20vh" }}></div>
        </div>
      </section>
    </aside>
  );
}
