import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    partialBundledLanguages: true,
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    supportedLngs: ["en", "fr"],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: "/locales/en/translation.json",
    //   loadPath: "/locales/fr/translation.json",
    // },
  });

export default i18n;
