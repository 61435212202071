import React from "react";
import { Link } from "react-router-dom";
import { useGetOverviewQuery } from "../../store/DashBoard/DashBoardApi";
import { useTranslation } from "react-i18next";

export default function DashBoardComp() {
  const overview = useGetOverviewQuery();
  const { t } = useTranslation();
  return (
    <div className="page_container md:px-[1rem] py-[.5rem]">
      <section className="companies">
        {/* heading */}
        <section className="section_container max-[850px]:flex-col  flex max-[350px]:flex-col items-start gap-[.75rem] justify-between mb-4  xl:pr-[.55rem]">
          <h4 className="font-2 ml-2 max-[350px]:ml-0 text-[2rem]">
            {t("company")}
          </h4>
          {/* button flex container */}
          <div className="buttons_container flex items-center justify-center gap-[.3rem]">
            <button className="min-w-[8rem] text-[1rem] rounded-[6px] p-[.6rem] outline-none border-solid border-[1px] border-btnPrimary text-btnPrimary flex items-center gap-[.5rem]">
              <img src="/svg/report.svg" alt="report svg" />
              {t("download-report")}
            </button>
            <Link to="/companies/add">
              <button className="md:min-w-[10rem] min-w-[6.6rem] rounded-[6px] p-[.6rem] text-[1rem] decoration-none outline-none border-solid border-[1px] border-btnPrimary bg-btnPrimary text-white flex items-center justify-center">
                {t("add-company")}
              </button>
            </Link>
          </div>
        </section>

        <section className="row  mb-5  flex-wrap ">
          {[
            {
              name: "Total Companies",
              count: overview?.data?.data?.overview?.company?.totalCompanies,
              suffixIcon: (
                <small
                  style={{ textDecoration: "none" }}
                  className="w-full flex items-end justify-end text-yellow"
                >
                  {t("view-all")}
                </small>
              ),
            },
            {
              name: "Active Companies",
              count:
                overview?.data?.data?.overview?.company?.totalActiveCompanies,
            },
            {
              name: "In-Active Companies",
              count:
                overview?.data?.data?.overview?.company?.totalInActiveCompanies,
            },
          ]?.map((item, i) => (
            <div className="w-full col-lg-4   flex-grow h-full" key={i}>
              <div className="item-card px-lg-2">
                <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
                    <div className=" text-[18px]">
                      {item?.name === "Total Companies"
                        ? t("total-companies")
                        : item?.name === "Active Companies"
                        ? t("active-companies")
                        : t("inactive-companies")}
                    </div>

                    <div>
                      <div className=" text-[18px]">
                        <img src="/icon/dots.svg" />
                      </div>
                    </div>
                  </div>

                  <section className=" text-[#060D1C] flex items-center justify-between w-full group-hover:text-white font-5  text-[25px]">
                    {!overview?.data?.data?.overview?.company
                      ? "..."
                      : item?.count}
                    <Link
                      to={"/"}
                      className="block text-[.85rem] !text-[#4F008A] mt-[.5rem]"
                    >
                      <div style={{ textDecoration: "none" }}>
                        {item?.suffixIcon}
                      </div>
                    </Link>
                  </section>
                </div>
              </div>
            </div>
          ))}
        </section>
      </section>
      {/* managers */}
      <section className="managers">
        {/* heading */}
        <section className="section_container flex justify-between mb-4">
          <h4 className="font-2 ms-2 text-[2rem]">{t("managers")} </h4>
          {/* button flex container */}
        </section>

        <section className="row  mb-5  flex-wrap ">
          {[
            {
              name: "Total Managers",
              count: overview?.data?.data?.overview?.manager?.totalManager,
            },
            {
              name: "Active Managers",
              count:
                overview?.data?.data?.overview?.manager?.totalActiveManager,
            },
            {
              name: "In-Active Managers",
              count:
                overview?.data?.data?.overview?.manager?.totalInActiveManager,
            },
          ]?.map((item, i) => (
            <div className="w-full col-lg-4   flex-grow h-full" key={i}>
              <div className="item-card px-lg-2">
                <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
                    <div className=" text-[18px]">
                      {" "}
                      {item?.name === "Total Managers"
                        ? t("total-managers")
                        : item?.name === "Active Managers"
                        ? t("active-managers")
                        : t("inactive-managers")}
                    </div>

                    <div>
                      <div className=" text-[18px]">
                        <img src="/icon/dots.svg" />
                      </div>
                    </div>
                  </div>

                  <div className=" text-[#060D1C] group-hover:text-white font-5  text-[25px]">
                    {!overview?.data?.data?.overview?.company
                      ? "..."
                      : item?.count}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </section>

      {/*Med Reps  */}
      <section className="med_reps">
        {/* heading */}
        <section className="section_container flex justify-between mb-4">
          <h4 className="font-2 ms-2 text-[2rem]">{t("med-reps")}</h4>
        </section>

        <section className="row  mb-5  flex-wrap ">
          {[
            {
              name: "Total Med Reps",
              // count: Companies?.data?.companies?.length,
              count: overview?.data?.data?.overview?.medRep?.totalMedReps,
            },
            {
              name: "Active Med Reps",
              count: overview?.data?.data?.overview?.medRep?.totalActiveMedReps,
            },
            {
              name: "In-Active Med Reps",
              count:
                overview?.data?.data?.overview?.medRep?.totalInActiveMedReps,
            },
          ]?.map((item, i) => (
            <div className="w-full col-lg-4   flex-grow h-full" key={i}>
              <div className="item-card px-lg-2">
                <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
                    <div className=" text-[18px]">
                      {" "}
                      {item?.name === "Total Med Reps"
                        ? t("total-medreps")
                        : item?.name === "Active Med Reps"
                        ? t("active-medreps")
                        : t("inactive-medreps")}
                    </div>

                    <div>
                      <div className=" text-[18px]">
                        <img src="/icon/dots.svg" />
                      </div>
                    </div>
                  </div>

                  <div className=" text-[#060D1C] group-hover:text-white font-5  text-[25px]">
                    {!overview?.data?.data?.overview?.company
                      ? "..."
                      : item?.count}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </section>
    </div>
  );
}
