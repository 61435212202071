import React from "react";
import { TableComp } from "../../../Common/TableComp";
import { CustomerColumns } from "./Data";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useGetAllCompanyManagersQuery } from "../../../../store/DashBoard/DashBoardApi";
import { useTranslation } from "react-i18next";

export default function CompanyManagersComp({ Data, managers }) {
  // const manager = useGetAllCompanyManagersQuery();
  const navigate = useNavigate();
  // const {} =useHis

  // console.log(Data, "Data");
  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          <FiEdit size={18} color="#4F008A" /> View Full Info
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/manager/${row?.id}`, { state: { ...row } });
        // setShowCavans(true);
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          {/* <FiEdit size={18} color="#4F008A" />  */}
          <img src="/icon/refresh.svg" />
          Add New Manager
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/manager/add`);
        // setShowCavans(true);
      },
    },

    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center px-3">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Company
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        // setShowCavans(true);
      },
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="w-[100%]">
      <div className="row  g-3  flex-wrap  mb-[54px]">
        {[
          { name: "Total Managers", count: managers?.totalManager },
          { name: "Active Managers", count: managers?.totalActiveManager },
          { name: "In-Active Managers", count: managers?.totalInActiveManager },
        ]?.map((item, i) => (
          <div className="w-full col-lg-6 col-xl-4  flex-grow h-full" key={i}>
            <div className="px-lg-2 ">
              <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                <div className="d-flex justify-content-between align-items-start mb-3">
                  {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
                  <div className=" text-[18px]">
                    {item?.name === "Active Managers"
                      ? t("active-managers")
                      : item?.name === "In-Active Managers"
                      ? t("inactive-managers")
                      : t("total-managers")}
                  </div>

                  <div>
                    <div className=" text-[18px]">
                      <img src="/icon/dots.svg" />
                    </div>
                  </div>
                </div>

                <div className=" text-[#060D1C] group-hover:text-white font-5  text-[25px]">
                  {managers?.totalManager ? item?.count : "..."}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="overflow-hidden">
        {/* {console.log(Data, "Data")} */}

        {Data.length === 0 ? (
          <div className="image_container flex items-center justify-center flex-col w-full h-full">
            <img
              src="/svg/no-data.svg"
              className="w-[10rem] h-[10rem]"
              alt=""
            />
            <div className="title font-[500] text-[1.3rem] text-center">
              No Data Found
            </div>
          </div>
        ) : (
          <TableComp
            Data={Data}
            columnsComp={CustomerColumns(
              tableDropDown,
              Array.isArray(Data) && [...Data]
            )}
            // pagination
          />
        )}
      </div>
    </div>
  );
}
