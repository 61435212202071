import React from "react";
import { useTranslation } from "react-i18next";

export default function Header({ pages, setListPage, listPage, showFull }) {
  const { t } = useTranslation();
  console.log(pages, "pages");
  return (
    <div className="">
      <section className="flex justify-between md:flex-row flex-col-reverse md:items-end items-start border-b-[1px] border-b-[#EDE5F3] mb-[40px]">
        <div className="flex gap-3">
          {pages?.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => setListPage(item)}
                className={`text-[18px] cursor-pointer  from-neutral-600  pb-[20px] ${
                  item === listPage &&
                  "border-b-[#A162F7] text-[#A162F7] border-b-[2px]"
                }`}
              >
                {item === "Overview"
                  ? t("overview")
                  : item === "Managers"
                  ? t("managers")
                  : item === "Med Reps"
                  ? t("med-reps")
                  : ""}
              </div>
            );
          })}
        </div>
        {showFull && (
          <div className="">
            <div className="text-[22px] font-semibold md:text-right">
              {t("company-profile")}
            </div>
            <div className="text-[#635F65] text-[14px] text-right pb-[20px]">
              {t("view-company-details")}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
