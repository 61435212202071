import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import MedRepComp from '../../Components/modules/MedRep'

export default function MedRep() {
  return (
    <AdminLayout header='main' active={5}>
      <MedRepComp/>
    </AdminLayout>
  )
}
