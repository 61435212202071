import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ImageComponent } from "./FallbackImage";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export function TableComp({ columnsComp, Data = [], pagination }) {
  const { t } = useTranslation();

  const hasRows = Data && Data.length > 0;
  const conditionalRowStyles = hasRows ? {} : { display: "table-header-group" };

  console.log(Data);

  if (!pagination) {
    return null; // or a loader/spinner
  }

  const { currentPage, totalPages, onPageChange } = pagination;
  const totalItems = Data.length;

  return (
    <TableCompStyled className="px-2">
      <DataTable
        columns={columnsComp}
        data={Data}
        customStyles={customStyles}
        noHeader={true}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={
          <div className="flex-col items-center">
            <ImageComponent src="/icon/circlewithslashgreen.svg" />
            <div className="text-[#282728] text-[22px] font-medium">
              {t("no-data-found")}
            </div>
          </div>
        }
      />
      <section className="pagination_component mt-[3rem] w-full h-full flex items-center justify-between">
        <div className="pagination_controls flex items-end justify-end w-full">
          <button
            className={`text-[.9rem] ${
              currentPage === 1 ? "text-[#757575]" : "text-[#282728]"
            }`}
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {t("previous")}
          </button>
          <div className="page_number_component flex items-center justify-center gap-[.4rem] mx-2">
            {Array.from({ length: totalPages }).map((_, i) => (
              <button
                key={i}
                className={
                  i + 1 === currentPage
                    ? " flex items-center justify-center text-center text-[.9rem] font-[500] text-white bg-[#A162F7] w-[1.5rem] rounded-[3px] min-h-[1.7rem]"
                    : "flex items-center justify-center text-center text-[.9rem] font-[500] text-[#282728] w-[1.5rem] rounded-[3px] min-h-[1.7rem] border-solid border-[1px] border-gray-200"
                }
                onClick={() => onPageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
          <button
            className={`text-[.9rem] ${
              currentPage === totalPages ? "text-[#757575]" : "text-[#282728]"
            }`}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage <= totalPages}
          >
            {t("next")}
          </button>
        </div>
      </section>
    </TableCompStyled>
  );
}

const customStyles = {
  headCells: {
    style: {
      minWidth: "200px",
    },
  },
};

const TableCompStyled = styled.span`
  padding: 0 5px;
  .rdt_TableHeadRow {
    font-family: "Gilroy-Medium", sans-serif !important;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #edeef2;
    color: #757575;
  }
  .rdt_TableRow {
    padding-top: 15px;
    padding-bottom: 20px;
    border: none;
    font-weight: 500;
    color: #757575;
  }
  .rdt_TableRow:nth-child(even) {
    // background: #EDEBF7;
  }
  .rdt_TableRow {
    border: 0.5px solid #edeef2;
  }
  // .rdt_Table {
  //   padding-bottom: 150px;
  // }
`;
