import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import {
  useGetAllCompaniesQuery,
  useGetAllCompanyManagersQuery,
  useGetUserQuery,
} from "../../../store/DashBoard/DashBoardApi";
import ManagerPage from "./submodules/ManagerPage";
import Header from "./submodules/Header";
import ShowProfileTop from "./submodules/showProfileTop";
import TopHeader from "../../Common/TopHeader";
import CompanyManagersComp from "./submodules/CompanyManagers";
import { BsCalendar2Event } from "react-icons/bs";
import SideDesc from "./submodules/SideDesc";
import { useForm } from "react-hook-form";

export default function ViewCompanyComp({
  listPage,
  setListPage,
  handleOnSubmit,
  showImage,
  fileLoading,
  handleFileChange,
  handleButtonClick,
  fileInputRef,
  control,
  handleSubmit,
  setValue,
  errors,
  modalShow,
  setModalShow,
  isLoading,
}) {
  const [company, setCompany] = useState();
  const location = useLocation();
  const { id } = useParams();

  const { data } = useGetUserQuery(id);

  console.log(data?.data, "Data");
  useEffect(() => {
    if (id) {
      setValue("id", data?.data?.id);
      setValue("firstName", data?.data?.firstName);
      setValue("lastName", data?.data?.lastName);
      setValue("email", data?.data?.email);
      setValue("contactPhoneNo", data?.data?.phoneNumber);
      setValue("country", data?.data?.country);
      setValue("state", data?.data?.state[0]);
      setValue("address", data?.data?.company?.address);

      //phoneNumber
      //state
    }
  }, [data?.data?.id]);

  const navigate = useNavigate();

  // console.log(data?.data, "UserData!");

  const formList = [
    {
      name: "firstName",
      placeholder: data?.data?.firstName,
      label: "First Name",
      value: data?.data?.firstName,
    },
    {
      name: "lastName",
      placeholder: data?.data?.lastName,
      label: "Last Name",
      value: data?.data?.lastName,
    },
    {
      name: "country",
      placeholder: data?.data?.country,
      label: "Country",
      style: "col-lg-6",
      value: data?.data?.country,
    },
    {
      name: "state",
      placeholder: data?.data?.state[0],
      label: "State",
      style: "col-lg-6",
      value: data?.data?.state[0],
    },
    {
      name: "email",
      label: "Email Address",
      placeholder: data?.data?.email,
      value: data?.data?.email,
    },
    {
      name: "contactPhoneNo",
      placeholder: data?.data?.phoneNumber,
      label: "Contact Person",
      iconRight: "+234",
      value: data?.data?.phoneNumber,
    },
    {
      name: "address",
      placeholder: data?.data?.company?.address,
      value: data?.data?.company?.address,
      label: "Full Address",
    },
  ];

  return (
    <div className={`flex gap-2 h-min-[90vh]`}>
      <div className={`pb-[69px] w-full`}>
        <ShowProfileTop
          company={data?.data}
          handleOnSubmit={handleOnSubmit}
          handleButtonClick={handleButtonClick}
          uploadImage={showImage}
          fileLoading={fileLoading}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          isLoading={isLoading}
        />

        {/*  */}
        <ManagerPage errors={errors} control={control} formList={formList} />
      </div>
    </div>
  );
}
