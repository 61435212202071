import React from "react";
import { ImageComponent } from "./FallbackImage";
import Skeleton from "react-loading-skeleton";
import { RiMenu2Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export default function Header({ data, setToogle, setToggleSide }) {
  const { i18n } = useTranslation();
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  // console.log(data, "userProfile");

  return (
    <div className="border-bottom w-full md:px-[2.3rem] px-[.5rem] flex  items-center justify-between">
      {/* <div className="cursor-pointer d-lg-none bg-yellow-800" onClick={() => setToogle(true)}>
        <RiMenu2Line size={30} />
      </div> */}

      <button
        onClick={() => setToggleSide(true)}
        className="burger_icon hidden max-[800px]:block h-[2rem] w-[2rem]"
      >
        <img src="/svg/burger.svg" alt="burger-icon" />
      </button>
      <section className="header-icons py-3 d-flex gap-2 gap-lg-4 justify-content-end w-full">
        {/* <button onClick={() => changeLanguage("fr")}>tr</button>
        <button onClick={() => changeLanguage("en")}>en</button> */}
        <div
          className="bg-gray rounded-circle p-3 d-flex justify-content-center align-items-center"
          style={{ width: "60px", height: "60px" }}
        >
          <ImageComponent width={24} height={24} src="/icon/global.png" />
        </div>
        <div
          className="bg-gray rounded-circle p-1 d-flex justify-content-center align-items-center"
          style={{ width: "60px", height: "60px" }}
        >
          {/* <ImageComponent
            width={20}
            height={20}
            src="/icon/notification-bing.png"
          /> */}
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="64"
              height="64"
              rx="32"
              fill="#4F008A"
              fill-opacity="0.05"
            />
            <path
              d="M31.9091 25.9346V29.5673"
              stroke="#4F008A"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M31.9309 21.0908C27.9164 21.0908 24.6655 24.3417 24.6655 28.3563V30.6472C24.6655 31.389 24.36 32.5017 23.9782 33.1345L22.5927 35.4472C21.7418 36.8763 22.3309 38.469 23.9018 38.9926C29.1164 40.7272 34.7564 40.7272 39.9709 38.9926C41.4436 38.5017 42.0764 36.7781 41.28 35.4472L39.8945 33.1345C39.5127 32.5017 39.2073 31.3781 39.2073 30.6472V28.3563C39.1964 24.3635 35.9236 21.0908 31.9309 21.0908Z"
              stroke="#4F008A"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M35.5418 39.4399C35.5418 41.4363 33.9055 43.0727 31.9091 43.0727C30.9164 43.0727 30 42.6581 29.3455 42.0036C28.6909 41.349 28.2764 40.4327 28.2764 39.4399"
              stroke="#4F008A"
              stroke-width="1.5"
              stroke-miterlimit="10"
            />
            <path
              d="M38.9601 26.8801C41.7878 26.8801 44.0801 24.5878 44.0801 21.7601C44.0801 18.9324 41.7878 16.6401 38.9601 16.6401C36.1324 16.6401 33.8401 18.9324 33.8401 21.7601C33.8401 24.5878 36.1324 26.8801 38.9601 26.8801Z"
              fill="#D63434"
            />
          </svg>
        </div>
      </section>
    </div>
  );
}
