import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import ViewCompanyComp from "../../Components/modules/Company/viewCompany";
import { useForm } from "react-hook-form";
import {
  DashBoardApi,
  useUpdateCompanyManagerMutation,
  useGetAllCompaniesQuery,
  useUpdateCompanyMutation,
  useUploadFilesMutation,
  useGetAllCompanyMedRepsQuery,
  useGetCompanyQuery,
} from "../../store/DashBoard/DashBoardApi";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export default function ViewCompanyMain() {
  const navigate = useNavigate();
  const [listPage, setListPage] = useState("Overview");
  const [showImage, setShowImage] = useState();
  const [uploadFile, { isLoading: fileLoading }] = useUploadFilesMutation();

  const dispatch = useDispatch();
  const { id } = useParams();
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const maxSize = 500 * 1024; // 500KB in bytes
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const formData = new FormData();
        formData.append("file", file);
        const responses = await uploadFile(formData);
        setShowImage(responses?.data?.data?.file_link);
        setValue("image", responses?.data?.data?.file_link);
        // Here, you can perform any actions with the selected file
        console.log("Selected file:", responses?.data?.data);
      } else {
        toast.error("File type or size not allowed.");
        console.log("File type or size not allowed.");
      }
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      address: "",
      contactPhoneNo: "",
      contactPerson: "",
      state: "",
      country: "",
      image: "",
    },
  });

  const { data: Companies } = useGetAllCompaniesQuery();

  const { data: CompanyMedRep } = useGetAllCompanyMedRepsQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  // console.log(CompanyMedRep, "CompanyMedRep");
  //useGetAllCompanyMedRepsQuery

  const [
    updateCompanyManager,
    { isLoading, isSuccess: updateCompanyIsSuccess },
  ] = useUpdateCompanyMutation();

  const handleOnSubmit = async (data) => {
    console.log(data, "handleOnSubmit");

    const payload = {
      ...data,

      // country:data?.country?.label,
      id: id,
    };

    const response = await updateCompanyManager(payload);

    if (response?.data) {
      toast.success("Company Updated successfully...");
      navigate(-1);
      // refresh();

      setModalShow(true);
      // reset();
      // setShowImage();
    }

    if (response?.error) {
      toast.error(response?.error?.error?.data?.message);
      toast.error(response?.error?.data?.message);
    }
  };

  return (
    <AdminLayout header={listPage === "Overview" ? false : "main"} active={4}>
      <ViewCompanyComp
        listPage={listPage}
        setListPage={setListPage}
        control={control}
        handleOnSubmit={handleSubmit(handleOnSubmit)}
        setValue={setValue}
        updateCompanyIsSuccess={isLoading}
        Companies={Companies}
        handleButtonClick={handleButtonClick}
        fileInputRef={fileInputRef}
        handleFileChange={handleFileChange}
        fileLoading={fileLoading}
        showImage={showImage}
      />
    </AdminLayout>
  );
}
